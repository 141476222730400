import { PageRendererProps, Link } from "gatsby"
import React, { ReactNode } from "react"

interface Props extends PageRendererProps {
  title: string
  children: ReactNode
}

export const Layout = (props: Props) => {
  const { title, children } = props

  return (
    <div className="max-w-3xl mx-auto py-20 px-8">
      <header className="mb-8">
        <h1 className="text-5xl font-semibold">
          <Link className="no-underline flex flex-row items-center" to={`/`}>
            {title}
          </Link>
        </h1>
      </header>
      <main>{children}</main>
      <footer>© {new Date().getFullYear()}</footer>
    </div>
  )
}
